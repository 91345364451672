<template>
	<div class="page-joinus">
		<!-- <img style="width: 100%;" src="https://dh123.hndh6666.com/images/joinus.png" /> -->
		<div class="top-img"></div>
		
		<div class="bgbox-white" style="padding: 50px 0;">
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 4 }">
				<div class="module1-conts">
					<div class="module-title">
						<span class="title-cn">招聘岗位</span>
						<!-- <span class="title-en">LEARNING PRODUCTS</span> -->
						<span class="title-txt">心动不如“薪”动，缺的就是你，欢迎加入德合商服大家庭</span>
					</div>
				</div>
			</el-col>
		</div>
		
		<div class="bgbox-white">
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 0 }" :lg="{ span: 16, offset: 4 }">
				
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:24 }">
					<div class="wow slideInLeft" style="margin-top: 50px;">
						<div class="container">
							<img class="con-img1" src="https://dh123.hndh6666.com/images/bg_xszy.png" />
							<!-- <div class="con-img1"></div> -->
						</div>
						<div class="contentv">
							<span>岗位职责：</span>
							<br/><span>开拓中小微商户，进行商务谈判，为商户提供行业解决方案，持续有效跟踪商户并完成销售计划</span>
							<br/><span>与公司各部门配合，及时处理商户的反馈、投诉和建议，提高顾客满意度</span>
							<br/><span>归档和更新所有目标商户拜访、协议、服务条款等有关的文件和数据，确保信息在数据库中得到正确的维护</span>
							
							<br/><br/><span>任职资格：</span>
							<br/><span>中专、中技及以上学历，专业不限；</span>
							<br/><span>两年以上市场拓展销售经验，具有良好的沟通能力及客户服务意识；</span>
							<br/><span>目标导向，具备较强的人际沟通能力及逻辑思维能力，善于换位思考，高效整合内外部资源促成销售业绩</span>
							<br/><span>富有激情和创新理念，追求个人职业发展和公司利益的双赢</span>
							<br/><span>积极乐观，有强烈的成功欲望，诚实可靠，以公司利益为重</span>
						</div>
					</div>
				</el-col>
			</el-col>
		</div>
		
		<div class="bgbox-white">
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 0 }" :lg="{ span: 16, offset: 4 }">
				
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:24 }">
					<div class="wow slideInRight" style="margin: 50px 0;">
						<div class="container">
							<img class="con-img2" src="https://dh123.hndh6666.com/images/bg_xsjl.png" />
						</div>
						<div class="contentv">
							<span>岗位职责：</span>
							<br/><span>负责建立和维护客户合作关系，推进移动聚合支付，新餐饮，新零售行业项目的合作；</span>
							<br/><span>负责开发本地各行业连锁商户资源，为客户提供整体的支付产品；</span>
							<br/><span>完成公司下达的销售目标；</span>
							<br/><span>协调公司内部资源及时处理客户问题，做好后续维护服务工作，提高客户满意度，并挖掘新的合作机会；</span>
							
							<br/><br/><span>任职资格：</span>
							<br/><span>大专以上学历，2年以上支付或相关行业大客户销售经验；</span>
							<br/><span>有连锁、品类头部各行业商户资源；</span>
							<br/><span>有口碑、美团等大客户销售的工作经验；</span>
							<br/><span>具有连锁商户谈判、签约的项目案例，连锁商户运营管理经验；</span>
							<br/><span>有较强的人际沟通能力及逻辑思维能力；</span>
						</div>
					</div>
				</el-col>
			</el-col>
		</div>
		
	</div>	
</template>

<script>
	export default {
		data() {
			return {
				
			}
		},
		components:{
			
		},
		mounted() {
			
		},
	
		methods: {
			
		}
	}
</script>

<style lang="scss" scoped>
	.page-joinus{
		width: 100%;
		height: auto;
	}
	.top-img{
		height: 540px;
		background-image: url("https://dh123.hndh6666.com/images/joinus.png");
		background-repeat: no-repeat;
		background-position: center;
	}
	.bgbox-white{
		background-color: #fff;
		// padding: 50px 0;ss
	}
	
	.module1-conts {
		display: flex;
		flex-direction: column;
		justify-content: center;
		
		p {
			font-size: 16px;
			color: #333;
			text-indent: 2em;
		}
		
		.module-title {
			text-align: center;
			padding-bottom: 20px;
	
			.title-cn {
				display: block;
				font-size: 30px;
				font-weight: bold;
				color: #1f1f1f;
			}
	
			.title-txt {
				margin-top: 20px;
				display: block;
				// font-size: 18px;
				font-weight: bold;
				color: #1f1f1f;
			}
	
			.title-en {
				display: block;
				font-size: 12px;
				font-weight: 400;
				color: #757575;
				line-height: 20px;
			}
		}
	}
	
	.container{
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: row;
		justify-content: center;
		
		img{
			z-index: -1;
			height: 540px;
		}
	}
	
	.contentv{
		width: 100%;
		height: auto;
		margin-top: -210px; 
		padding: 30px;
		background-color: #ffffff;
		box-shadow: -9px 17px 49px 23px rgba(0, 0, 0, 0.06);
		font-family: 'PingFang SC';
		font-style: normal;
		font-weight: 400;
		font-size: 32px;
		line-height: 182%;
		letter-spacing: -0.035em;
		color: #000000;
		z-index: 999;
	}
	
	// 移动端css
	@media screen and (max-width: 767px) {
		.top-img{
			height: 540px;
			background-image: url("https://dh123.hndh6666.com/images/joinus2.png");
			background-size:100% 100%;
			background-repeat:no-repeat;
		}
		.con-img1{
			width: 700px;
			max-height: 200px;
			z-index: -1;
		}
		.con-img2{
			width: 700px;
			max-height: 200px;
			z-index: -1;
		}
		.contentv{
			margin-left: 3.5%;
			width: 80%;
			height: auto;
			margin-top: -80px; 
			padding: 30px;
			background-color: #ffffff;
			box-shadow: -9px 17px 49px 23px rgba(0, 0, 0, 0.06);
			font-family: 'PingFang SC';
			font-style: normal;
			font-weight: 400;
			font-size: 15px;
			line-height: 182%;
			/* or 58px */
			letter-spacing: -0.035em;
			color: #000000;
			z-index: 999;
		}
	}
</style>